export const showBottomBar = (pathname) => {
  let show = true;

  pathname = pathname.split("/")[1];

  switch (pathname) {
    case "":
      show = false;
      break;
    case "login":
      show = false;
      break;
    case "forgot-password":
      show = false;
      break;
    case "onboarding":
      show = false;
      break;
    case "reset":
      show = false;
      break;
    case "invitation":
      show = false;
      break;
  }

  return show;
};

export const showHamburgerIcon = (pathname) => {
  let show = true;

  pathname = pathname.split("/")[1];

  switch (pathname) {
    case "":
      show = false;
      break;
    case "login":
      show = false;
      break;
    case "forgot-password":
      show = false;
      break;
    case "onboarding":
      show = false;
      break;
    case "reset":
      show = false;
      break;
    case "invitation":
      show = false;
      break;
  }

  return show;
};
