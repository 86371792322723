// External Dependencies
import { Button } from "@mui/material";
import { styled } from "@mui/system";

// Internal Dependencies
import Logo from "../../images/KeyOpsLogo.svg";

const CustomizedHeader = styled("header")`
  width: 100%;
  height: 80px;
  background: #f3f9fc;
`;
const KeyOpsLogo = styled("img")`
  height: 60px;
  margin-top: 10px;
`;

export default function Login({ action, text }) {
  return (
    <CustomizedHeader variant="contained" onClick={action}>
      {/* Turn this into a react component */}
      <KeyOpsLogo src={Logo} alt="React Logo" />
      {text}
    </CustomizedHeader>
  );
}
