export const earningsData = {
  firstName: "Mike",
  lastName: "Laine",
  email: "hello@mikelaine.com",
  engagements: [
    {
      title: "Product test - basic survey",
      completedDate: "Jul 27",
      amount: 50,
    },
    {
      title: "Pandemic influence on dermatology practice",
      completedDate: "Jul 27",
      amount: 100,
    },
    {
      title: "Product test - document",
      completedDate: "Jul 27",
      amount: 90,
    },
  ],
};
