import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material/";
import { styled } from "@mui/system";

import InteracIcon from "./interac-logo.png";

const CustomGrid = styled(Grid)`
  padding: 10px;
`;

const EarningBox = styled(Box)`
  display: flex;
  padding: 10px;
  background: #f3f9fc;
`;

const LabelStyle = styled(Typography)`
  font-size: 10px;
  font-weight: 700;
  padding: 2px;
  color: grey;
`;

const EarningsRow = (props) => {
  const [text, setText] = React.useState("View");
  const [hidden, setHidden] = React.useState(true);

  const handleChange = () => {
    if (text == "View") {
      setText("Hide");
      setHidden(false);
    } else {
      setText("View");
      setHidden(true);
    }
  };

  return (
    <>
      <div
        style={{
          margin: 5,
          display: "flex",
          height: "1px",
          backgroundColor: "grey",
        }}
      />
      <CustomGrid container>
        <Grid item xs={6} sm={6} md={6}>
          <Typography>{props.title}</Typography>
        </Grid>
        <Grid
          align="right"
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          item
          md={2}
        >
          <Typography>{props.completedDate}</Typography>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={2}>
          <Typography color="primary">${props.amount}</Typography>
        </Grid>
        <Grid align="right" item xs={3} sm={3} md={2}>
          <Button onClick={() => handleChange()}>{text}</Button>
        </Grid>
        <Grid hidden={hidden} item md={12}>
          <EarningBox>
            <Box style={{ padding: 20 }}>
              <Avatar
                variant={"rounded"}
                src={InteracIcon}
                style={{
                  width: 70,
                  height: 70,
                }}
              />
              <Typography
                style={{ padding: "20px 0px 0px 0px" }}
                sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              >
                <b>Completed</b>
              </Typography>
              <Typography
                sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              >
                {props.completedDate}
              </Typography>
            </Box>
            <Box style={{ padding: 20 }}>
              <Typography>
                <b>${props.amount}</b> was sent to your email {props.email} via
                interact e-Transfer
              </Typography>
              <Typography>
                Security answer: <b>KEYOPS</b>
              </Typography>
              <LabelStyle>
                e-Transfers are processed Monday to Saturday between 6am - 11pm
                EST except bank holidays.
              </LabelStyle>
            </Box>
          </EarningBox>
        </Grid>
      </CustomGrid>
    </>
  );
};

export default EarningsRow;
