import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  CardHeader,
} from "@mui/material/";
import { styled } from "@mui/system";
// import { gql, useQuery } from "@apollo/client";

// Internal Dependencies
import { earningsData } from "./test-data.js";
import EarningsRow from "./EarningsRow";

const ButtonContainer = styled(Container)`
  display: flex;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
  width: 40%;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const FilledButton = styled(Button)`
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  white-space: no-wrap;
`;

const SeeThroughButton = styled(Button)`
  border-radius: 50px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1.1px;
  border: 2px solid;
`;

const Headline = styled(Typography)`
  margin: 20px;
  padding: 0px 10px 0px 10px;
  font-weight: 300;
  letter-spacing: 1.1px;
`;

const LabelStyle = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  padding: 2px;
`;

const CustomGrid = styled(Grid)`
  padding: 0px 10px 0px 10px;
`;

const HideCol = styled(Grid)`
  display: {
    md: "none";
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    hidden: true;
  }
`;

const totalEarnings = (engagements) => {
  var total = 0;
  for (let i = 0; i < engagements.length; i++) {
    total += engagements[i].amount;
  }
  return total;
};

const Earnings = (props) => {
  const { firstName, lastName, email, engagements } = earningsData;

  return (
    <Container>
      <Box mb={4}>
        <Paper elevation={3} mb={4}>
          <HeaderContainer>
            <Headline variant={"h4"}>My Earnings</Headline>
            <Headline variant={"h4"}>${totalEarnings(engagements)}</Headline>
          </HeaderContainer>
          <Box mb={2}>
            <Headline variant={"h6"}>Earnings History</Headline>
          </Box>
          <CustomGrid container>
            <Grid item xs={6} sm={6} md={6}>
              <LabelStyle>Engagements ({engagements.length})</LabelStyle>
            </Grid>
            <Grid
              align="right"
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              item
              md={2}
            >
              <LabelStyle>Completed On</LabelStyle>
            </Grid>
            <Grid align="right" item xs={3} sm={3} md={2}>
              <LabelStyle>Earned</LabelStyle>
            </Grid>
            <Grid align="right" item xs={3} sm={3} md={2}>
              <LabelStyle>Details</LabelStyle>
            </Grid>
          </CustomGrid>
          <>
            {engagements.map(({ title, completedDate, amount }) => {
              return (
                <EarningsRow
                  key={title} //unique key for ech child
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  title={title}
                  completedDate={completedDate}
                  amount={amount}
                />
              );
            })}
          </>
        </Paper>
      </Box>
      <CustomGrid container>
        <Grid item xs={6} sm={6} md={3}>
          <FilledButton variant="contained">Back to Dashboard</FilledButton>
        </Grid>
        <Grid align="center" item xs={6} sm={6} md={3}>
          <SeeThroughButton variant="outlined">Sign Out</SeeThroughButton>
        </Grid>
      </CustomGrid>
    </Container>
  );
};
export default Earnings;
